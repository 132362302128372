export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'posts',
      displayName: 'menu.posts',
      meta: {
        icon: 'post_add',
      },
    },
    {
      name: 'appointments',
      displayName: 'menu.appointments',
      meta: {
        icon: 'calendar_month',
      },
    },
    {
      name: 'influencers',
      displayName: 'menu.influencers',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'merchandises',
      displayName: 'menu.merchandises',
      meta: {
        icon: 'store',
      },
    },
    {
      name: 'tickets',
      displayName: 'menu.tickets',
      meta: {
        icon: 'contact_support',
      },
    },
  ] as INavigationRoute[],
}
